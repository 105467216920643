import axios, {AxiosInstance} from "axios";
import {CreateOrganizationModel} from "./organization.slice";
import {Country} from "../../models/country";
import {CreateOrganization} from "../../models/createOrganization";
import {Organization} from "../../models/organization";
import {UserInfo} from "../../models/userInfo";
import { LocalSegment } from "../../models/localSegment";

class OrganizationService {
  private _client: AxiosInstance;
  private _token: Promise<string> | undefined;

  constructor() {
    this._client = axios.create();
    this._client.interceptors.request.use((config) => {
      return this.GetToken().then((token) => {
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
      });
    });
  }

  private GetToken = (): Promise<string> => {
    if (this._token) {
      return this._token;
    }

    this._token = new Promise<string>((resolve) => {
      window.gigya.accounts.getJWT({
        callback: (resp: any) => {
          resolve(resp.id_token);
        },
      })
    });

    return this._token;
  }

  GetCurrentOrganization = () => {
    return this._client.get<UserInfo>(`/api/user`)
      .then(a => a.data?.currentOrganization)
      .catch(() => null);
  };

  GetCountries = (): Promise<Country[]> => {
    return this._client.get<Country[]>(`/api/config/countries`)
      .then(a => a.data || []);
  };

  GetLocalSegments = (): Promise<LocalSegment[]> => {
    return this._client.get<LocalSegment[]>(`/api/config/localSegments`)
      .then(a => a.data || []);
  };

  Create = (model: CreateOrganizationModel, countries: Country[]): Promise<Organization> => {
    const data = {
      name: model.name,
      industry: model.industry,
      sfCorporateSegment: model.sfCorporateSegment,
      sfLocalSegment: model.sfLocalSegment,
      billingContactPersonFirstName: model.billingContactPersonFirstName,
      billingContactPersonLastName: model.billingContactPersonLastName,
      billingEmailAddress: model.billingEmailAddress,
      federalTaxId: model.federalTaxId,
      vatNumber: model.vatNumber,
      useOrganizationAddressForBilling: true,
      billingOrganizationName: model.address.companyName,
      address: {
        ...model.address,
        country: countries.find(a => a.codeAlpha2 === model.address.countryCode)
      }
    } as CreateOrganization;

    return this._client.post<Organization>(`/api/organization`, data)
      .then(a => a.data);
  }
}

export default new OrganizationService();