import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, GlobalNavigationBar, LoadingIndicator, PageWidth, SiteFooter, ToastContext } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { FailToastOptions } from '../../models/toast';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { setLoading, setLocalSegments, setOrganizationCountries } from './organization.slice';
import OrganizationService from './OrganizationService';
import { organizationSchema } from './schema';
import { navigateByUrl } from '../../utilities/navigate';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background: ${COLORS.neutral_50};
`;

const HeaderContainer = styled.div`
  pointer-events: none;
`;

const StyledPageWidth = styled(PageWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  margin-top: 16px;

  ${BREAKPOINTS.MEDIUM} {
    margin-top: 32px;
  }

  ${BREAKPOINTS.LARGE} {
    width: 100%;
    margin-top: 64px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const CreateOrganizationPage = () => {
  const { t } = useTranslation('Organization');
  const { addToast } = useContext(ToastContext);
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => state.organization.loading);
  const details = useAppSelector((state) => state.organization.value);
  const countries = useAppSelector((state) => state.organization.countries);

  useEffect(() => {
    if (loading) return;

    if (!organizationSchema(t).isValidSync(details, { context: { countries } })) {
      navigate(
        {
          pathname: `/organization/create/details`,
          search: search.toString(),
        },
        { replace: true },
      );
    }
  }, [loading, countries]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    OrganizationService.GetCurrentOrganization().then((org) => {
      if (org) {
        return navigateByUrl(search.get('onSuccess') || process.env.REACT_APP_CONNECT_URL!);
      } else {

        return Promise.all([OrganizationService.GetLocalSegments(), OrganizationService.GetCountries()])
          .then((data) => {
            dispatch(setLocalSegments(data[0]));
            dispatch(setOrganizationCountries(data[1]));
            dispatch(setLoading(false));
          })
          .catch(() => addToast(t('A problem occurred – please try again'), FailToastOptions));
      }
    });
  }, []);

  return (
    <PageContainer>
      <HeaderContainer>
        <GlobalNavigationBar maxWidth={1600} useMaxWidth={true} name={search.get('gig_application') || 'Identity'} />
      </HeaderContainer>
      <StyledPageWidth>
        {loading && (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        )}
        {!loading && (
          <Container>
            <Outlet />
          </Container>
        )}
      </StyledPageWidth>
      <SiteFooter id={'footer'} toTopText={t('TOP')} copyrightText={t('Copyright © {{year}} Laerdal Medical. All Rights Reserved.', { year: new Date().getFullYear() })!} />
    </PageContainer>
  );
};
