import {createSlice} from "@reduxjs/toolkit";
import {Country,} from "../../models/country";
import {Industry} from "../../models/industry";
import { LocalSegment } from "../../models/localSegment";

export interface CreateOrganizationAddress {
  companyName?: string;
  additionalName?: string;

  addressLine?: string;
  addressLineTwo?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  countryCode?: string;

  phoneNumber?: string;
  email?: string;

}

export interface CreateOrganizationModel {
  name: string;
  industry?: Industry;
  vatNumber: string;
  federalTaxId: string;
  sfCorporateSegment?: string;
  sfLocalSegment?: string;
  billingContactPersonLastName: string;
  billingContactPersonFirstName: string;
  billingEmailAddress: string;
  address: CreateOrganizationAddress
}

export interface OrganizationState {
  loading: boolean;
  countries: Country[];
  localSegments: LocalSegment[];
  value: CreateOrganizationModel;
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    loading: true,
    countries: [],
    localSegments: [],
    value: {
      name: '',
      vatNumber: '',
      federalTaxId: '',
      billingContactPersonFirstName: '',
      billingContactPersonLastName: '',
      billingEmailAddress: '',
      address: {
        companyName: '',
        additionalName: '',
        countryCode: '',
        addressLine: '',
        addressLineTwo: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
        email: ''
      }
    }
  } as OrganizationState,
  reducers: {
    setOrganizationCountries: (state, action) => {
      state.countries = action.payload;
    },
    setLocalSegments: (state, action) => {
      state.localSegments = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOrganizationModel: (state, action) => {
      state.value = {
        ...state.value,
        ...action.payload
      };
    }
  }
});

export const {
  setOrganizationCountries,
  setOrganizationModel,
  setLocalSegments,
  setLoading
} = organizationSlice.actions;

export default organizationSlice.reducer;