import React, {useContext, useMemo, useState} from 'react';
import styled from "styled-components";
import {
  BackButton, BREAKPOINTS,
  Button,
  Checkbox,
  COLORS, ComponentSStyling,
  ComponentTextStyle,
  ComponentXXSStyling,
  HyperLink, IconButton,
  Size, SystemIcons,
  ToastContext
} from "@laerdal/life-react-components";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAppSelector} from "../../../store";
import OrganizationService from "../OrganizationService";
import {FailToastOptions} from "../../../models/toast";
import {EXCLUDE_VAT_COUNTRIES, INCLUDE_FEDERAL_TAX_ID_COUNTRIES, CORPORATE_SEGMENT_OPTIONS} from "../config";
import {navigateByUrl} from "../../../utilities/navigate";
import {useController, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";


const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Header = styled.h3`
  border-bottom: 1px solid ${COLORS.neutral_200};

  padding-bottom: 32px;
`;


const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GroupHeader = styled.h6``;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;


const Panel = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: ${COLORS.white};
  padding: 0;
`;

const PanelHeader = styled.div`
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid ${COLORS.neutral_100};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 16px;
  gap: 32px;

  ${Group} {
    flex: 1;
  }

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
  }
`;


const Item = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemLabel = styled.div`
  ${ComponentXXSStyling(ComponentTextStyle.Regular, COLORS.neutral_500)};
`;
const ItemValue = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)};
`;


const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: ${COLORS.white};
  padding: 8px;
`;


const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    flex: 1;
  }

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
  }
`;

const schema = yup.object().shape({
  confirmed: yup.boolean().required().oneOf([true])
});

export const OrganizationConfirmStep = () => {
  const {addToast} = useContext(ToastContext);
  const {t} = useTranslation('Organization');

  const {control, handleSubmit, formState} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      confirmed: false
    }
  })

  const confirmed = useController({control, name: 'confirmed'});
  const [search] = useSearchParams();

  const value = useAppSelector(state => state.organization.value);
  const countries = useAppSelector(state => state.organization.countries);
  const localSegments = useAppSelector((state) => state.organization.localSegments);

  const navigate = useNavigate();

  const submit = () => {
    return OrganizationService.Create(value, countries)
      .then(() => navigateByUrl(search.get('onSuccess') || process.env.REACT_APP_CONNECT_URL!, navigate))
      .catch(() => addToast(t('A problem occurred – please try again'), FailToastOptions));
  }

  const billingDetails = useMemo(() => {
    const details: { label: string, value: string }[] = [];
    const country = countries.find(c => c.codeAlpha2 === value.address.countryCode);

    details.push({
      label: t('Country / Region'),
      value: country?.name ?? '-'
    })

    if (INCLUDE_FEDERAL_TAX_ID_COUNTRIES.includes(country?.codeAlpha2 ?? '')) {
      details.push({
        label: t('Federal Tax ID'),
        value: value.federalTaxId
      })
    }

    if (!EXCLUDE_VAT_COUNTRIES.includes(country?.codeAlpha2 ?? '')) {
      details.push({
        label: t('VAT number'),
        value: value.vatNumber
      })
    }

    const industry = CORPORATE_SEGMENT_OPTIONS(t).find(i => i.industry === value.industry?.toString())?.displayLabel;
    details.push({
      label: t('Industry'),
      value: industry ?? '-'
    })

    if(value.sfLocalSegment) {
      const segment = localSegments.find(i => i.localSegment === value.sfLocalSegment)?.description;
      details.push({
        label: t('Local Segment'),
        value: segment ?? '-'
      });
    }


    details.push({
      label: t('Billing representative'),
      value: `${value.billingContactPersonFirstName} ${value.billingContactPersonLastName}`
    });

    details.push({
      label: t('Billing email address'),
      value: value.billingEmailAddress
    });

    return details;
  }, [value, countries]);

  const address = useMemo(() => {
    const address: { label: string, value: string }[] = [];
    const country = countries.find(c => c.id === value.address.countryCode);

    address.push({
      label: t('Company or billing name'),
      value: value.address.companyName ?? '-'
    })

    address.push({
      label: t('Additional names'),
      value: value.address.additionalName ?? '-'
    })


    address.push({
      label: t('Address line 1'),
      value: value.address.addressLine ?? '-'
    })

    address.push({
      label: t('Address line 2'),
      value: value.address.addressLineTwo ?? '-'
    })

    address.push({
      label: t('City'),
      value: value.address.city ?? '-'
    })

    if (country?.states?.length) {
      const state = country.states.find(s => s.code === value.address.state)!;
      address.push({
        label: t('State / Province'),
        value: state.name
      })
    }

    address.push({
      label: t('Zip / Postal Code'),
      value: value.address.zipCode ?? '-'
    })

    address.push({
      label: t('Telephone and area code'),
      value: value.address.phoneNumber ?? '-'
    })

    return address;
  }, [value, countries]);

  return (
    <Page>
      <BackButton size={Size.Small} onClick={() => navigate({pathname: '../details', search: search.toString()})}>
        {t('Back')}
      </BackButton>
      <Wrapper>
        <Header>{t('Review and confirm')}</Header>

        <Panel>
        </Panel>

        <Panel>
          <PanelHeader>
            <GroupHeader>{value.name}</GroupHeader>
            <HyperLink variant={'default'}
                       href={''}
                       onClick={e => {
                         e.preventDefault();
                         e.stopPropagation();
                         navigate('../details');
                       }}>
              <SystemIcons.Edit/>
              <span>{t('Edit')}</span>
            </HyperLink>
          </PanelHeader>
          <PanelContent>
            <GroupContainer>
              <GroupHeader>{t('Billing details')}</GroupHeader>
              <Group>
                {
                  billingDetails.map((d, i) => (
                    <Item key={i}>
                      <ItemLabel>{d.label}</ItemLabel>
                      <ItemValue>{d.value}</ItemValue>
                    </Item>
                  ))
                }
              </Group>
            </GroupContainer>

            <GroupContainer>
              <GroupHeader>{t('Address')}</GroupHeader>
              <Group>
                {
                  address.map((d, i) => (
                    <Item key={i}>
                      <ItemLabel>{d.label}</ItemLabel>
                      <ItemValue>{d.value}</ItemValue>
                    </Item>
                  ))
                }
              </Group>
            </GroupContainer>
          </PanelContent>
        </Panel>

        <CheckBoxContainer>
          <Checkbox selected={confirmed.field.value}
                    invalid={confirmed.fieldState.invalid}
                    select={confirmed.field.onChange}
                    onBlur={confirmed.field.onBlur}
                    ref={confirmed.field.ref}>
            <Trans ns={'Organization'} i18nKey={'CONFIRM_CREATE_ORGANIZATION'}>
              I confirm, on behalf of the company I represent, the acceptance of the&nbsp;
              <HyperLink variant={'default'}
                         href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL!}
                         onClick={e => e.stopPropagation()}>Terms and Conditions</HyperLink>
            </Trans>
          </Checkbox>
        </CheckBoxContainer>
        <Actions>
          <Button variant='primary'
                  onClick={handleSubmit(submit)}
                  loading={formState.isSubmitting}
                  size={Size.Large}>
            {t('Create organization')}
          </Button>
        </Actions>
      </Wrapper>
    </Page>
  )
}