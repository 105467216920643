import * as yup from 'yup';
import {INCLUDE_FEDERAL_TAX_ID_COUNTRIES, LOCAL_SEGMENT_COUNTRIES} from "./config";
import {TFunction} from "i18next";


export const organizationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().ensure().required(t('This field is required')!),
    sfCorporateSegment: yup.string().when('address', (address, schema) => {
      return LOCAL_SEGMENT_COUNTRIES.includes(address[0].countryCode?.toUpperCase()) ? schema.required() : schema;
    }),
    industry: yup.number().when('address', (address, schema) => {
      return LOCAL_SEGMENT_COUNTRIES.includes(address[0].countryCode?.toUpperCase()) ? schema.required() : schema;
    }),
    vatNumber: yup.string().ensure(),
    federalTaxId: yup.string().ensure().when('$countryCode', ([value], schema) => INCLUDE_FEDERAL_TAX_ID_COUNTRIES.includes(value) ? schema.required(t('This field is required')!) : schema),
    billingContactPersonFirstName: yup.string().ensure().required(t('This field is required')!),
    billingContactPersonLastName: yup.string().ensure().required(t('This field is required')!),
    billingEmailAddress: yup.string().ensure().email(t('Please enter a valid email address')!).required(t('This field is required')!),
    sfLocalSegment: yup.string().when('address', (address, schema) => {
      return LOCAL_SEGMENT_COUNTRIES.includes(address[0].countryCode?.toUpperCase()) ? schema.required() : schema;
    }),
    address: yup.object().shape({
      companyName: yup.string().ensure().required(t('This field is required')!),
      additionalName: yup.string().ensure(),
      countryCode: yup.string().ensure().required(t('This field is required')!),
      addressLine: yup.string().ensure().required(t('This field is required')!),
      addressLineTwo: yup.string().ensure(),
      city: yup.string().ensure().required(t('This field is required')!),
      state: yup.string().ensure()
        .when('$countryCode', ([value], schema, options) =>
          options.context.countries?.find(a => a.codeAlpha2 == value)?.states
            ? schema.required(t('This field is required')!)
            : schema),
      zipCode: yup.string().ensure().required(t('This field is required')!),
      phoneNumber: yup.string().ensure().required(t('This field is required')!).matches(/[^a-zA-Z0-9]*[0-9]{3}[^a-zA-Z0-9]*[0-9]{3}[^a-zA-Z0-9]*[0-9]{4}$/, t('Please enter a valid phone number')!),
    }),
  });

